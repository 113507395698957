// Home.jsx
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from './App.module.css';
import { getSocket } from './socketservice';
import PopupModal from "./Popup";

export default function Home() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [joinMeetingId, setJoinMeetingId] = useState('');
  const [error, setError] = useState('');
  const [connected, setConnected] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const createAttemptedRef = useRef(false);
  const socketRef = useRef(getSocket());

  useEffect(() => {
    const socket = socketRef.current;

    const onConnect = () => {
      console.log('Connected to server with ID:', socket.id);
      setConnected(true);
    };

    const onDisconnect = (reason) => {
      console.log('Socket disconnected:', reason);
      setConnected(false);
    };

    const onRoomCreated = ({ roomId }) => {
      console.log('Room created successfully:', roomId);
      createAttemptedRef.current = false;
      setLoading(false);
      navigate(`/meeting/${roomId}`);
    };

    const onRoomExists = ({ roomId }) => {
      console.log('Room exists, joining:', roomId);

      // Store meeting data
      localStorage.setItem('meetingData', JSON.stringify({
        meetingId: roomId,
        isHost: false,
        joined: new Date().toISOString()
      }));

      // Join the room first
      socketRef.current.emit('join-room', { roomId });
    };

    const onJoinedRoom = ({ roomId, participants }) => {
      console.log('Successfully joined room:', roomId);
      setLoading(false);

      // Navigate only after successfully joining
      navigate(`/meeting/${roomId}`);
    };

    const onRoomNotFound = () => {
      console.log('Room not found');
      setError('Meeting ID not found. Please check and try again.');
      setLoading(false);
    };

    const onError = ({ message, type }) => {
      console.error('Socket error:', { message, type });
      createAttemptedRef.current = false;
      setError(message || 'An error occurred');
      setLoading(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('room-created', onRoomCreated);
    socket.on('room-exists', onRoomExists);
    socket.on('joined-room', onJoinedRoom);
    socket.on('room-not-found', onRoomNotFound);
    socket.on('error', onError);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('room-created', onRoomCreated);
      socket.off('room-exists', onRoomExists);
      socket.off('joined-room', onJoinedRoom);
      socket.off('room-not-found', onRoomNotFound);
      socket.off('error', onError);
    };
  }, [navigate]);

  const createNewMeet = useCallback(() => {
    if (loading || !connected || createAttemptedRef.current) return;

    try {
      createAttemptedRef.current = true;
      setLoading(true);
      setError('');

      const meetingId = Math.random().toString(36).substring(7);
      console.log('Creating new meeting with ID:', meetingId);

      localStorage.setItem('meetingData', JSON.stringify({
        meetingId,
        isHost: true,
        created: new Date().toISOString()
      }));

      socketRef.current.emit('create-room', { roomId: meetingId });

    } catch (err) {
      console.error("Error creating meeting:", err);
      createAttemptedRef.current = false;
      setError('Error creating meeting. Please try again.');
      setLoading(false);
    }
  }, [loading, connected]);

  const handleJoinMeeting = useCallback(() => {
    if (loading || !connected) return;

    const trimmedId = joinMeetingId.trim();

    if (!trimmedId) {
      setError('Please enter a meeting ID');
      return;
    }

    setLoading(true);
    setError('');

    console.log('Checking room existence:', trimmedId);
    socketRef.current.emit('check-room', { roomId: trimmedId });
  }, [loading, joinMeetingId, connected]);



  const goToSlide = (index) => {
    setCurrentIndex(index);
  };



  const slides = [
    <React.Fragment>
      <img src='/assets/Home/slide1.png' className={styles.sdl_img} alt="slide 1" />
      <h1 className={styles.sdl_hed}>Screen Sharing</h1>
      <p className={styles.sdl_des}>Share presentations, documents, or your entire screen with ease</p>
    </React.Fragment>,
    <React.Fragment>
      <img src='/assets/Home/slide2.png' className={styles.sdl_img} alt="slide 2" />
      <h1 className={styles.sdl_hed}>Screen Sharing</h1>
      <p className={styles.sdl_des}>Share presentations, documents, or your entire screen with ease</p>
    </React.Fragment>,
    <React.Fragment>
      <img src='/assets/Home/slide3.png' className={styles.sdl_img} alt="slide 3" />
      <h1 className={styles.sdl_hed}>Recording</h1>
      <p className={styles.sdl_des}>Never miss a moment! Record your meetings for later reference or to share with team members who couldn’t make it.</p>
    </React.Fragment>
  ];

  const handleCreateMeeting = (users) => {
    if (loading || !connected || createAttemptedRef.current) return;

    try {
      createAttemptedRef.current = true;
      setLoading(true);
      setError('');

      const meetingId = Math.random().toString(36).substring(7);
      console.log('Creating new meeting with ID:', meetingId);

      localStorage.setItem('meetingData', JSON.stringify({
        meetingId,
        isHost: true,
        created: new Date().toISOString(),
        users: users // Store users data
      }));

      // Emit create-room with users data
      socketRef.current.emit('create-room', {
        roomId: meetingId,
        users: users
      });

    } catch (err) {
      console.error("Error creating meeting:", err);
      createAttemptedRef.current = false;
      setError('Error creating meeting. Please try again.');
      setLoading(false);
    }
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>

      <React.Fragment>
        <header className={styles.header}>
          <img src="https://www.lucknowlions.com/logo.png" alt="Lucknow Lions" className={styles.logo} />
        </header>
        <main className={styles.main}>

          {loading && <div className={styles.loader_body}><div className={styles.loader} /></div>}


          <div className={styles.alignCenter}>

            <div className="carousel">
              <div className="carousel-content">
                {slides[currentIndex]}
              </div>
              <ul className="dots">
                {slides.map((slide, index) => (
                  <li
                    className={index === currentIndex ? 'active-dot' : 'dot'}
                    onClick={() => goToSlide(index)}
                  />
                ))}
              </ul>
            </div>




            {/* <div style={{ maxWidth: '600px' }}>
            <CCarousel dark interval={2000}>
              <CCarouselItem>
                <CImage src='/assets/Home/slide1.png' className={styles.sdl_img} alt="slide 1" />
                <h1 className={styles.sdl_hed}>Screen Sharing</h1>
                <p className={styles.sdl_des}>Share presentations, documents, or your entire screen with ease</p>
              </CCarouselItem>
              <CCarouselItem>
                <CImage src='/assets/Home/slide2.png' className={styles.sdl_img} alt="slide 2" />
                <h1 className={styles.sdl_hed}>One-Click Invitations</h1>
                <p className={styles.sdl_des}>Send meeting invites via email or a simple link. Joining a meeting has never been easier.</p>
              </CCarouselItem>
              <CCarouselItem>
                <CImage src='/assets/Home/slide3.png' className={styles.sdl_img} alt="slide 3" />
                <h1 className={styles.sdl_hed}>Recording</h1>
                <p className={styles.sdl_des}>Never miss a moment! Record your meetings for later reference or to share with team members who couldn’t make it.</p>
              </CCarouselItem>
            </CCarousel>
            <Slider {...settings}>
              <div />
              <div />
              <div /> 
            </Slider>
          </div> */}

          </div>

          {window.innerWidth > 600 ? <div className={styles.line} /> : null}

          <div className={styles.alignCenter}>
            <h1 className={styles.mainHed}>Create a Meet Now or Schedule for later</h1>
            <p className={styles.mainDes}>Transform the way you connect, collaborate, and communicate with Lucknow Lions Meet</p>

            <input
              className={styles.input}
              value={joinMeetingId}
              onChange={(e) => {
                setJoinMeetingId(e.target.value);
                setError('');
              }}
              placeholder="Enter Meeting ID..."
              disabled={loading || !connected}
            />
            {error && <p className={styles.error}>{error}</p>}
            {/* <button className={styles.btn} onClick={handleJoinMeeting}> {loading ? 'Processing...' : 'Join Meeting'}</button> */}

            <button
              className={`${styles.btn} ${styles.createBtn}`}
              onClick={() => handleJoinMeeting()}
              disabled={loading || !connected}
            >
              {loading ? 'Proceding...' : 'Join Meeting'}
            </button>


            {isModalOpen &&
              <PopupModal
                isOpen={true}
                onClose={() => setShowModal(false)}
                onSubmitUsers={handleCreateMeeting}
              />}

            <img src="/assets/Home/or.webp" alt='or' className={styles.or} loading="lazy" />
            <button className={styles.btn} onClick={() => setIsModalOpen(true)}>Create a NEW Meeting</button>

            <div className={styles.cards}>
              <div className={styles.card}>
                <div className={styles.card_tl}>Fast</div>
                <img className={styles.sdl_img} src="/assets/Home/flying.png" alt='flying' />
              </div>
              <div className={styles.card}>
                <div className={styles.card_tl}>Lighting</div>
                <img className={styles.sdl_img} src="/assets/Home/lighting.png" alt='lighting' />
              </div>
              <div className={styles.card}>
                <div className={styles.card_tl}>Seamless</div>
                <img className={styles.sdl_img} src="/assets/Home/seamless.png" alt='seamless' />
              </div>
            </div>

            <div className={styles.bigCard}>
              <h2 className={styles.bigCardHed}>We Provide various Video Qualities as below</h2>
              <div className={styles.qtlys}>
                <div className={styles.qlty}>360p</div>
                <div className={styles.qlty}>480p</div>
                <div className={styles.qlty}>720p</div>
                <div className={styles.qlty}>1080p</div>
              </div>
              <div className={styles.qtlys}>
                <div className={styles.qlty_2K}>2K</div>
                <div className={styles.qlty_4K}>4K</div>

              </div>
            </div>
          </div>
        </main>
        <footer className={styles.footer}>
          Terms & Conditions
        </footer>
      </React.Fragment>
    </>
  );
}